<template>
    <section class="repair-management" @click.stop="pickerclick">
        <div class="top-bar bg-white align-center">
          <div class="flex">
              <el-input   size="small" v-model="formSearch.keyWord" placeholder="请输入申请人/手机号"  style="margin-right: 10px;"/>
          </div>
            <el-date-picker   @change="pickerclickitr"   ref="startTimeRef" v-model="formSearch.startTime" type="date" value-format="yyyy-MM-dd"
                            format="yyyy-MM-dd" placeholder="请选择开始时间" style="margin-right: 10px;"
                            size="small"/>
            <el-date-picker   @change="pickerclickitr"   ref="endTimeRef" v-model="formSearch.endTime" type="date" value-format="yyyy-MM-dd"
                            format="yyyy-MM-dd" placeholder="请选择结束时间" style="margin-right: 10px;" size="small"/>
            <el-select ref="statusRef" v-model="formSearch.status" placeholder="请选择申请状态" size="small" style="margin-right: 10px;">
              <el-option label="全部" value="-1"/>
                <el-option label="申请中" value="0"/>
                <el-option label="开放中" value="1"/>
                <el-option label="待生效" value="2"/>
                <el-option label="申请结束" value="3"/>
                <el-option label="已关闭" value="4"/>
            </el-select>
            <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button size="small" @click="handleReset">重置</el-button>
            <el-button type="primary" size="small" @click="saveActivity">新增</el-button>
        </div>

        <r-e-table ref="tableRef" :dataRequest="getLockInstallList" :columns="tableColumn" :query="formSearch"
                   :height="790">
            <el-table-column slot="toolbar" label="申请房间号" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="ckHandle(row)">详情</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column slot="toolbar" label="操作" width="150">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" v-if="row.status===0" @click="details(row)">开放权限</span>
                        <span class="table-btn" v-if="row.status===0 || row.status===1" @click="scHandle(row)">关闭</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <dialog-save-activity ref="dialogSaveActivity" @handleSearch="handleSearch"/>

        <go-getails ref="goGetails" @handleSearch="handleSearch"/>
        <go-details ref="goDetails" @handleSearch="handleSearch"/>

    </section>
</template>

<script>
import {tableColumn} from "./data";
import {addUser, ShutDown} from "@/api/lock-permission";
import {MessageSuccess, MessageError} from "@custom/message";

export default {
    name: "lock-permission",
    data() {
        return {
            itr:true,
            tableColumn,
            formSearch: {
                keyWord: null,
                startTime: null,
                endTime: null,
                status: null
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        };
    },
    components: {
        dialogSaveActivity: () => import("./components/dialog-save-activity"),
        goGetails: () => import("./components/go-Getails"),
        goDetails: () => import("./components/go-Details")
    },
  deactivated(){
    // alert('离开')
    // 2024-7-10-陈守亮处理切换页面关闭组件
    this.pickerclick()
  },
    methods: {
        handleSearch() {
            // 搜索
            this.$refs["tableRef"].getTableData();
        },
        // 重置按钮
        handleReset() {
            this.formSearch = {}
            this.handleSearch()
            // setTimeout(() => {this.handleSearch();})
        },
        // 人员列表
        getLockInstallList(params) {
            return addUser(params);
        },

        // 详情
        ckHandle(row) {
            this.$refs["goDetails"].openDialog(row)
        },
        // 开放权限
        details(row) {
            this.$refs["goGetails"].openDialog(row);
        },
        // 关闭
        scHandle(row) {
            const that = this;
            const {uuid} = row;
            this.$confirm('此操作将关闭该权限,是否继续?', '注意', {type: 'warning'})
                .then(async () => {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading(loadingOptions);
                const paramData = {uuid};
                ShutDown(paramData).then(res => {
                    MessageSuccess("删除权限成功");
                    that.handleSearch();
                }).finally(() => loading.close());
            }).catch(() => {});
        },
        // 新增
        saveActivity() {
            this.$refs["dialogSaveActivity"].openDialog();
        },
      // 2024-4-16-新增点击空白关闭组件
      pickerclick(e){
        this.itr = !this.itr;
        if(this.itr){
          this.$refs['startTimeRef'].hidePicker();
          this.$refs['endTimeRef'].hidePicker();
        }
        this.$refs['statusRef'].blur();
        // this.$refs.goGetails.pickerclick()
      },
      pickerclickitr(){
        this.itr = true;
      },
    },
    mounted() {},
    watch: {}
}
</script>

<style lang="scss" scoped>
.repair-management {
    padding: VH(15px) VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(25px);
        padding: VH(15px) VW(15px);
        //justify-content: center;
    }

    /deep/ .r-e-table {
        .el-table .cell {
            //white-space: nowrap;
            text-align: center;
        }
    }

}




/deep/ .r-e-table {
  .el-table .cell  {
    text-align: justify;
    //white-space: nowrap;
    //text-align: center;
    height: 54px;
    line-height: 54px;
    //overflow-y: auto;

  }
  .el-table .is-left .cell .el-link .el-link--inner  {
    text-align: justify;
    //white-space: nowrap;
    //text-align: center;
    font-size: 12px;
    height: 54px;
    line-height: 14px;
    overflow-y: auto;
    //margin-top: 12px;
    color: #968e8e;
  }

}
</style>
